<template>
    <div v-if="isLoading">
        <div class="text-center text-danger screen-center" style="padding-top: 20%;">
            <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
            <strong>Cargando...</strong>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-sm-5">
                <div class="breadcrumb">
                    <h1>Modificar Configuracion</h1>
                    <div class="ml-3 pt-2">
                        <span class="font-weight-bold">
                            N°: {{ numeroRegistro }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-9">
                                <button class="btn th-custom-color"
                                @click="update">
                                    Guardar
                                </button>
                                <router-link :to="{ name: 'configuracion-index' }">
                                    <button
                                        style="margin-left: 10px;"
                                        class="btn btn-white border border-light">
                                        Regresar
                                    </button>
                                </router-link>
                            </div>
                            <div class="col-sm-3">
                                <div class="text-right">
                                    <multiselect
                                        v-model="paisSelected"
                                        :options="paises"
                                        track-by="id"
                                        label="descripcion"
                                        placeholder="Seleccionar un pais"
                                        deselectLabel=""
                                        selectLabel="Seleccionar"
                                        :searchable="true"
                                        @select="getCiudad">
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 py-3 px-2">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-4 pr-0 text-right">
                                                <label class="col-form-label">Proveedor de Software:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="nombreProveedor"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-4 pr-0 text-right">
                                                <label class="col-form-label">Nombre del Sistema:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    required
                                                    v-model="nombreSistema"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-4 pr-0 text-right">
                                                <label style="white-space: nowrap;" class="col-form-label">Cod. de Sistema Proveedor:</label>
                                            </div>
                                            <div class="col-sm-6">
                                                <input type="password"
                                                    class="form-control"
                                                    autocomplete="off" name="codigo" id="codigo"
                                                    required
                                                    v-model="codSistemaProveedor"
                                                >
                                            </div>
                                            <div class="col-sm-1" style="padding:8px;">
                                                <input type="checkbox"
                                                v-model="verCodigo">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-4 pr-0 text-right">
                                                <label style="white-space: nowrap;" class="col-form-label">Nit:</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    required
                                                    v-model="nit"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-4 pr-0 text-right">
                                                <label style="white-space: nowrap;" class="col-form-label">Logo Empresa:</label>
                                            </div>
                                            <div class="file-select col-sm-4">
                                                <label :class="update === '' || update === null
                                                ? 'btn btn-secondary col-form-label' : 'btn th-custom-color'"
                                                style="width:160px;">
                                                    <input type="file" name="file" 
                                                    class="form-control-file"
                                                    accept="image/jpeg,image/png"
                                                    hidden 
                                                    ref="file"
                                                    v-on:change="obtenerImagen($event)"
                                                    > Cargar
                                                </label>
                                            </div>
                                            <div class="col-sm-2"
                                                v-show="logo || urlImage">
                                                <button class="btn btn-secondary" type="button"
                                                name="archivo-1"
                                                style="width:100px;"
                                                @click="verImagen()">
                                                    Ver
                                                </button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <b-col md="12">
                                <b-tabs
                                    class="tabNav"
                                    active-nav-item-class="nav nav-tabs"
                                    content-class="mt-3"
                                    >
                                        <b-tab title="Sitio Web" active>
                                            <tabSitioWeb/>
                                        </b-tab>
                                        <b-tab title="CAFC">
                                            <tabCafc/>
                                        </b-tab>
                                </b-tabs>    
                            </b-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

import { call, get, sync } from 'vuex-pathify';
import tabSitioWeb from '../components/tabSitioWeb.vue';
import tabCafc from '../components/tabCafc.vue';

export default {
    name: "Edit",
    components: {
        Multiselect,
        tabSitioWeb,
        tabCafc,
    },
    data() {
        return {
            verCodigo: false,
        };
    },
    created() {
        this.getDataEdit();
    },
    methods: {
        ...call("configuracion/configuracionUpdate", [
            "getDatoInical",
            "getCiudad",
            "UpdateConfiguracion",
        ]),
        async getDataEdit() {
            const EDIT_OK = await this.getDatoInical(atob(this.$route.params.id));
            if (EDIT_OK) {
                this.isLoading = false;
            }
        },
        async update() {
            this.isLoading = true;
            const UPDATE_OK = await this.UpdateConfiguracion(atob(this.$route.params.id));
            if (UPDATE_OK) {
                this.$router.push({ name: 'configuracion-index' });
            }
            this.isLoading = false;
        },
        obtenerImagen(event) {
            this.logo = event.target.files[0];
            this.urlImage = URL.createObjectURL(this.logo);
        },
        verImagen() {
            if (this.urlImage) {
                window.open(this.urlImage);
            }
        },
    },
    computed: {
        ...get("configuracion/configuracionUpdate", [
            "paises",
            "numeroRegistro",
        ]),
        ...sync("configuracion/configuracionUpdate", [
            "isLoading",
            "paisSelected",
            "nombreSistema",
            "codSistemaProveedor",
            "nit",
            "nombreProveedor",
            "urlImage",
            "logo",
        ]),
    },
    watch: {
        verCodigo(new1) {
            if (new1) {
                document.getElementById("codigo").type  = "text";
            } else {
                document.getElementById("codigo").type  = "password";
            }
        },
    },
    beforeDestroy() {
        this.$store.dispatch('configuracion/configuracionUpdate/reset');
    }

}

</script>

<style scoped>
.btnVerImg{
padding-left: 10px; 
margin-left:10px;
border:0px; 
box-shadow: none;
}
</style>